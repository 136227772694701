$org-theme: "fai";
/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN scss CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

#js-licensing {
	display: none !important
}

/* Nasconde la scrollbar di default per Webkit browsers */
.my-nav::-webkit-scrollbar {
	height: 7px;
	width: 15px;
}

/* Traccia della scrollbar */
.my-nav::-webkit-scrollbar-track {
	background: #f1f1f1;
	margin: 10px;
	border-radius: 0.428rem;
}

/* La maniglia della scrollbar */
.my-nav::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 0.428rem;
	transition: background-color 0.2s ease;
}

/* Quando si passa sopra la maniglia della scrollbar */
.my-nav::-webkit-scrollbar-thumb:hover {
	background: #555;
}

/* Su Firefox, puoi utilizzare scrollbar-width e scrollbar-color */
body {
	scrollbar-width: thin;
	scrollbar-color: #888 #f1f1f1;
}

.tabs-container {
	flex-wrap: nowrap !important;
	overflow-x: auto !important;
	max-width: 100% !important; // O qualsiasi larghezza tu voglia impostare
}

.nav-item .nav-link {
	white-space: nowrap !important;
}

#schedulerShifts .e-appointment {
	height: 40px !important;
	overflow: inherit;
	display: flex;
	align-items: center;
	border-radius: 0.428rem;
}

.avatar-scheduler {
	height: 35px !important;
	width: 35px !important;
}

#schedulerShifts .e-appointment-details {
	/* 	background-color: white;
 */
	margin-left: 1%;
	border-radius: 0.428rem;
}

.turni-table-container {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.table-wrapper {
	flex: 1;
	overflow-x: auto;
	/* in case table exceeds container width */
}

.legend {
	flex-basis: 20%;
	/* adjust this based on desired width for the legend */
	margin-left: 20px;
	max-width: 20%;
	/* adjust this too */
}

@media print {

	html,
	body {
		height: 98%;
	}

	.legend p {
		font-size: 12px;
		margin-bottom: 5px;
	}

	#turni-table-container {
		position: absolute;
		left: 0;
		top: 0;
	}

}

.page-break {
	display: none;
	page-break-after: always;
}


.error-message {
	color: red;
	margin: 10px 0;
}

.remove-icon {
	float: right;
	cursor: pointer;
	color: red;
}

#rtePrint .e-richtexteditor .e-rte-content .e-content {
	float: left;
	width: 100%;
}

#rtePrint .content-wrapper {
	width: auto;
	margin: 0;
}